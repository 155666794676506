.sf-funnel {
  margin-bottom: 100px;
  background: #51bc51;
  color: white;
}

.sf-funnel-canteens-wrapper {
  margin-left: 20px;
}

.sf-funnel-big-button {
  max-width: 400px;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 1.2rem;
  background: var(--primary-color);
  color: white
}

.sf-funnel-next-button {
  margin: 1em;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
}

.sf-funnel-next-button:hover,
.sf-funnel-big-button:hover {
  cursor: pointer;
}

.sf-funnel input {
  border: none;
  border-bottom: 1px solid gray;
  padding: 0.5em;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
}

.sf-funnel-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sf-funnel-button-group > .sf-funnel-big-button:first-of-type {
  margin-right: 2em;
}

.sf-button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .sf-funnel-button-group {
    flex-direction: column;
  }

  .sf-funnel-button-group > .sf-funnel-big-button:first-of-type {
    margin: 0;
    margin-bottom: 1em;
  }
}
