.sf-settings h1 {
  color: black !important;
}

.sf-settings input {
  border: none;
  border-bottom: 1px solid gray;
  padding: 0.5em;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
}

.sf-settings-button {
  margin: 1em;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--success-color);
  color: white;
  cursor: pointer;
}

.sf-settings-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sf-settings-cancel-link {
  margin: 1em;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: #bb3e56;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
