.react-share__ShareButton circle {
  fill: #094249;
}

.sf-copy-button {
  height: 115px;
  width: 115px;
  border-radius: 60px;
  margin: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #094249;
  cursor: pointer;
}

.sf-copy-button svg {
  top: 20px;
  left: 20px;
  position: relative;
  fill: #fffcdc;
}
