.sf-funnel-step-title {
  color: white;
  text-align: center;
}

.sf-funnel-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
