.sf-share-meal-page-wrapper .react-share__ShareButton circle {
  fill: #fffcdc;
}

.sf-share-meal-page-wrapper {
  padding: 20px;
  background: #fffcdc;
  box-sizing: border-box;
  color: #094249;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.sf-share-meals-native svg {
  fill: #094249;
  margin: 20px;
  margin-left: 0;
}

.sf-share-meal-page-wrapper-inner {
  max-width: 400px;
}

.sf-share-meals-header-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sf-share-meals-header-img {
  height: 200px;
}

.sf-share-meal-text-box {
  padding: 5px 20px;
  font-size: 12px;
  background: #e2f7cc;
  color: black;
  max-width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(136, 136, 136, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(136, 136, 136, 1);
  box-shadow: 0px 1px 5px 0px rgba(136, 136, 136, 1);
}

.sf-share-meal-page-wrapper .sf-copy-button {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  margin: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fffcdc;
  cursor: pointer;
}

.sf-share-meal-page-wrapper .sf-copy-button svg {
  top: 10px;
  left: 10px;
  position: relative;
  fill: #094249;
}

.sf-share-meals-share-button {
  padding: 10px 20px;
  border-radius: 5px;
  background: #094249;
  color: white;
  text-decoration: none;
  font-weight: 500;
  border: 1px solid #fffcdc;
}

.sf-share-meals-share-button:hover {
  background: #fffcdc;
  color: #094249;
  border: 1px solid #094249;
}
