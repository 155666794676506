.sf-radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.sf-radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.sf-radio-button-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.sf-radio-button-container:hover input ~ .sf-radio-button-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.sf-radio-button-container input:checked ~ .sf-radio-button-checkmark {
  background-color: var(--primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.sf-radio-button-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.sf-radio-button-container input:checked ~ .sf-radio-button-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.sf-radio-button-container .sf-radio-button-checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
