.sf-progress-bar-wrapper {
  height: 100px;
  width: 100%;
}

.sf-progress-bar {
  height: 20px;
  border-radius: 13px;
  background: var(--primary-color);
}

.sf-progress-bar-label {
  display: block;
  margin-bottom: 5px;
  color: var(--primary-color);
}
